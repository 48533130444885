import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import Image from "../components/image";

function Feature({
    contentPosition,
    img,
    svgImg,
    label,
    title,
    description,
    hasImg,
    padding,
    moreButton,
    moreButtonUrl,
}) {
    return (
        <div
            className={`${
                contentPosition === "left"
                    ? `md:flex-row`
                    : `md:flex-row-reverse`
            } flex flex-col-reverse items-center w-full max-w-6xl mx-auto ${padding}`}
        >
            <div className="flex-1 w-full">
                <span className="uppercase tracking-widest text-xs text-gray-500">
                    {label}
                </span>

                <h2 className="text-2xl md:text-4xl leading-tight mb-6 mt-2 font-display pr-6">
                    {title}
                </h2>

                <p className="text-lg md:text-xl text-gray-600 dark:text-gray-400">
                    {description}
                </p>

                {moreButton === true && (
                    <div className="mt-8">
                        <Link
                            to={moreButtonUrl || null}
                            className="text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 no-underline hover:underline border-b border-dashed border-gray-400 inline w-auto pb-1"
                        >
                            Lebih lengkap &rarr;
                        </Link>
                    </div>
                )}
            </div>

            {hasImg === true && (
                <div
                    className="flex-1 max-w-full px-6 md:p-6 h-full"
                    style={{ minWidth: `350px` }}
                >
                    {img !== null && <Image filename={img} alt="" />}
                    {svgImg !== null && svgImg}
                </div>
            )}
        </div>
    );
}

Feature.defaultProps = {
    contentPosition: `left`,
    hasImg: true,
    padding: `py-8 px-4 md:px-20 md:py-10`,
    moreButton: false,
};

Feature.propTypes = {
    contentPosition: PropTypes.string,
    img: PropTypes.string,
    svgImg: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    hasImg: PropTypes.bool,
    padding: PropTypes.string,
    moreButton: PropTypes.bool,
    moreButtonUrl: PropTypes.string,
};

export default Feature;
